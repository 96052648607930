<template>
  <div class="h-full">
    <div v-if="isActive" class="relative z-10 check-estate mx-6">
      <h5 class="title-addEstate">بررسی ملک</h5>
      <div class="check-estate__form-group">
        <label>کد پستی را وارد کنید</label>

        <input
            type="number"
            pattern="\d*"
            class="input-border text-center mt-6"
            placeholder="کد پستی"
            v-model="postalCode"
        >
        <span class="text-sm text-red-500 text-right inline-block mt-1">{{ errorText }}</span>
      </div>
      <div class="w-full self-center">
        <a
            class="btn-custom"
            @click="verifyPostalCode"
        >
          بررسی و ادامه
          <span class="svg-btn-icon2 bg-orange transform rotate-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>

        </span>
        </a>
      </div>

    </div>
    <NotAccess v-else/>
  </div>
</template>

<script>
import NotAccess from './NotAccess'
import axios from "../../plugins/axios";
import { mapState } from 'vuex'
import addEstate from "../../store/modules/addEstate";
export default {
  name: "CheckZipCode",
  components :{
    NotAccess
  },
  data(){
    return{
      postalCode : '' ,
      errorText : '',
      isActive : false
    }
  },
  methods:{
    verifyPostalCode(){
      axios.post('/validatation/postal-code',{
        value : this.postalCode
      }).then(({data})=>{
        this.$store.dispatch('addEstate/getPostalCode' , this.postalCode)
        this.$router.push({name : 'AddEstateStep1'})
      }).catch((err)=>{
        this.errorText = err.message
      })
    }
  },
  computed :{
    ...mapState({
      postal_code : state => state.addEstate.postal_code
    })
  },
  mounted() {
    if (this.postal_code !== undefined && this.postal_code !== ''){
      this.postalCode = this.postal_code
    }
    let checkAccess = JSON.parse(localStorage.getItem('user'))
    checkAccess['real_estate'].active === true ? this.isActive = true : this.isActive = false
  }
}
</script>

<style scoped>

</style>