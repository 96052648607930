<template>
  <div class="flex flex-col">
    <img class="relative top-16 right-4 w-36 z-10" src="../../assets/images/home.svg" alt="">
    <div class="relative m-auto mx-6 mt-28 z-10 splash">
      <div class="not-access">
        <h6 class="title-addEstate">ثبت آگهی</h6>
        <span class="not-access__description">
          بنگاه دار عزیز،
ما هنوز در حال بررسی اطلاعات  احراز هویتت هستیم
به محض تایید از سمت ما،
میتونی به راحتی آکهی هاتو پست کنی
        </span>
        <div class="not-access__button">
          <router-link :to="{name : 'HomeRealEstate'}" class="btn-custom">
            برگشت به خانه
            <span class="svg-btn-icon transform -rotate-180">
          <svg xmlns="http://www.w3.org/2000/svg" width="11.985" height="11.985" viewBox="0 0 11.985 11.985">
            <path id="ic_arrow_back_24px" d="M15.985,9.243H6.869l4.187-4.187L9.993,4,4,9.993l5.993,5.993,1.056-1.056-4.18-4.187h9.116Z" transform="translate(-4 -4)" fill="#FF7400"/>
          </svg>
        </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotAccessRealEstate",
  components :{

  }

}
</script>

<style scoped>

</style>